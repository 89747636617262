.taches_titre {
  margin-left: 1%;
  margin-top: 2%;
  margin-bottom: 1%;
}
.error_message {
  color: red;
}
.priorite-tache-elevee {
  color: red;
}

.priorite-tache-moyenne {
  color: #014f77;
}

.priorite-tache-faible {
  color: green;
}
