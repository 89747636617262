.custom-nav .nav-link {
  margin-right: 50px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}
.custom-nav .nav-link .bi {
  margin-right: 5px;
}

.custom-navbar {
  background-color: #014f77;
  width: 100%;
  position: fixed;
}

@media (max-width: 768px) {
  .custom-navbar {
    position: fixed;
    height: auto;
    width: 100%;
  }
  .nav-link {
    text-align: center;
  }
}
