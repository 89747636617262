/* Styles pour la boîte de chat */
.chat-box {
  background-color: #f0f0f0; /* Couleur de fond de la boîte de chat */
  padding: 20px; /* Espace intérieur de la boîte de chat */
}

/* Styles pour les messages */
.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  max-width: 70%;
  word-wrap: break-word;
}

.message.self {
  align-self: flex-end;
  background-color: #0084ff;
  color: #fff;
}

.message-content {
  font-size: 16px;
  line-height: 1.4;
}

.message-footer {
  font-size: 10px;
  color: rgb(210, 203, 203);
  font-weight: bold;
}
