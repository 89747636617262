.cop_copyright {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #014F77;
  font-size: 14px;
  display: flex;
  justify-content: center;
}

.co {
  color: white;
  transform: translateY(35%); /* Centrer verticalement */
}