.landing_image{
    margin-left:25%;
}
.ima{
    width: 60%;
}  
.manaraka {
  background-color: #014F77;
  top: 85%;
  margin-left: 23%;
  height: auto;
  width: 50%;
  padding: 20px;
}

.tt {
  color: white;
  text-align: center;
}

 
.coco {
  display: flex;
  justify-content: space-around;
  margin-top: 4%;
  margin-left: 1%;
}

.cocontenu {
  list-style-type: none;
  padding: 0;
}

.cocontenu li {
  flex: 1;
  background-color: transparent;
  transition: background-color 0.3s;
  padding: 10px;
  text-align: center;
  margin-left: 4%;
  margin-right: 4%;
}
  .cocontenu li:hover {
    background-color: #014F77;
    border-radius: 8px;
    color: white;
  }
 
  .offre_titre{
    text-align: center;
    margin: 3%;
  }

.liste_offre ul {
  list-style-type: none;
  justify-content: space-between;
  padding: 0;
  margin-top: 5%;
  margin-left: 3%;
  margin-right: 3%;
}

.liste_offre li {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5%;

}

.checklist {
  margin-top: 10px;
  text-align: center;
}

.checklist p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checklist .bi {
  margin-right: 5px;
}

.blue-bg {
  background-color: #014F77;
  color: white;
  margin-left: 15%;
  margin-top: 10%;
  border-radius: 5px;
  height: 10%;
  width: 70%;
}

.gray-bg {
  background-color: gray;
  color: white;
  margin-left: 15%;
  margin-top: 10%;
  border-radius: 5px;
  height: 10%;
  width: 70%;
}

.danger-bg {
  background-color: #dc3545;
  color: white;
  margin-left: 15%;
  margin-top: 10%;
  border-radius: 5px;
  height: 10%;
  width: 70%;
}

.bsc, .pro, .prem {
  background-color: transparent;
  border: none;
  border-radius: 8px;
  padding: 5px 20px;
  margin-top: 15
}
.test{
  text-align: center;
}
.isa{
  color: #014F77;
  font-weight: bold;
}
.roa{
  color: #014F77;
  font-weight: bolder;
}
.telo{
  color: grey;
  font-weight: bolder;
}
.efatra{
  color: grey;
  font-weight: bolder;
}
.dimy{
  color: #dc3545;
  font-weight: bolder;
}
.enina{
  color: #dc3545;
  font-weight: bolder;
}

.landing_apro {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
}
.prop_titre{
  text-align: center;
  margin-top: 4%;
}
.content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.parun, .pardeux {
  text-align: center;
  margin-top: 4% !important;
}

.prop_image {
  margin: 20px;
}

.prop_image img {
  max-width: 100%;
  height: auto;
}
.plaM{
  text-align: center;
  margin: 3%;
}
.obj{
  text-align: center;
  margin: 3%;

}
@media screen and (min-width: 768px) {
  .landing_apro {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }

  .content_container {
    flex-direction: row;
    align-items: flex-start;
  }

  .parun, .pardeux {
    margin: 0;
    flex: 1;
    text-align: left;
  }

  .prop_image {
    margin: 0;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .liste_offre ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin-top: 5%;
    margin-left: 3%;
    margin-right: 3%
  }
  
  .liste_offre li {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(33.33% - 20px);
  }
  .cocontenu {
    list-style-type: none;
    display: flex;
    padding: 0;
  }
  
}

.featu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  gap: 3%;
}
.feat p{
  font-size: large;
  margin-bottom: 3%;
  margin-left: 7%;
}

.feature_liste {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20%;
}

.feature_liste img {
  max-width: 100%;
  height: auto;
}

.lorem {
  color: #014F77;
  margin-top: 10px;
}
.feat_titre{
  text-align: center;
  margin-top: 5%;
  margin-bottom: 3%;
}
.contact_landing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1%;
}

.contact_1 {
  flex: 1;
  margin-left: 5%;
}
.contact_1 h3{
  font-weight: bold;
  margin-bottom: 5%;
  margin-left: 35%;
}
.contact_1 h5{
  font-weight: bold;
  margin-bottom: 3%;
  margin-left: 5%;

}
.contact_1 p{
  font-size: large;
  margin-left: 5%;
  margin-bottom: 3%;
}

.formulaire_contact {
  flex: 1;
  margin-top: 6%;
}
.twite, .insta, .faceboo, .linkd {
  font-size: 30px;
  margin-left: 5%;
}

.twite {
  color: #1DA1F2;
}

.insta {
  color: #E4405F;
}

.faceboo {
  color: #1877F2;
}

.linkd {
  color: #0A66C2;
}
.icon-success {
  color: #ffffff;
  background-color: #00cc00;
  border-radius: 20%;
}

.icon-danger {
  color: #ffffff;
  background-color: #ff0000;
  border-radius: 20%;
}
