.custom-navbar {
  background-color: #014f77;
  height: 20% !;
  width: 100%;
}

.custom-navbar .navbar-nav .nav-link {
  color: white;
}

.logo_blanche img {
  width: 70px;
  height: auto;
}
