.sidebar {
  width: 200px;
  height: 84vh;
  position: fixed;
  top: 11%;
  right: -200px;
  background-color: #014F77;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease;
  z-index: 1000;
}

.sidebar.active {
  right: 0;
}
.sidebar ul{
  margin-top: 45%;
}
.sidebar ul li {
  margin-bottom: 20%;
  margin-top: 20%;
  margin-right: 10%;
  padding: 8px;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
}

.sidebar ul li span {
  margin-right: 10px;
}

.sidebar ul li:hover {
  background-color: #d0d0d0;
}


